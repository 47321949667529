import React from 'react';
// images
import Logo from '../assets/logo21.png';
// import Logo from '../assets/logo.svg';
import {Link} from 'react-scroll';

const links = [
  {
    id: 1,
    text: 'home',
    title: 'Home',
    offset: -200,
  },
  {
    id: 2,
    text: 'about',
    title: 'About',
    offset: 0,
  },
  {
    id: 3,
    text: 'specialty',
    title: 'Skills',
    offset: 0,
  },
  {
    id: 4,
    text: 'work',
    title: 'Projects',
    offset: 0,
  },
  // {
  //   id: 5,
  //   text: 'contact',
  //   title: 'Contact',
  //   offset: 0,
  // },
];

const Header = () => {
  return <header className='py-8'>
    <div className='container mx-auto'>
      <div className='flex justify-between items-center'>
        <div className='flex items-center gap-x-4'>
        {/* logo */}
        <a href="#">
          <img className='w-20 rounded-full' src={Logo} alt="Michael Dang" />
        </a>
        {/* buttons */}
        <nav
          className="hidden md:flex gap-x-4 items-center justify-center
          top-0 left-0 w-full py-6 px-12
          text-black"
        >
          <div className="container mx-auto flex justify-between max-w-[440px]">
            {links.map(link => {
              const {id, text, icon, title, offset} = link;
              return (
                <Link 
                  key={id}
                  to={text}
                  // activeClass='active'
                  smooth='easeInOutQuart'
                  // smooth={true}
                  spy={true}
                  offset={offset}
                  className='cursor-pointer w-[50px] h-[50px] flex items-center
                  justify-center text-white'>
                    {title}
                </Link>
              )
              })}
          </div>
        </nav>
        </div>
        <div>
          <Link 
            to='contact' 
            activeClass='active'
            smooth={true}
            spy={true}
            // offset={-200}
            className='cursor-pointer w-[60px] h-[60px]'>
            <button href="#contact" className='btn btn-lg'>
              Contact me</button>
          </Link>
        </div>
        {/* <button className='btn btn-sm'>Work with me</button> */}
      </div>
    </div>
  </header>;
};

export default Header;
